export type Module = {
  id: keyof User['modules']
  name: string
  description: string
  color: string
  icon: `i-mdi-${string}`
  disabled: boolean
}

export const MODULES: Module[] = [
  {
    id: 'admin',
    name: 'Administración',
    description: 'Gestión del sistema, administración de roles y configuraciones.',
    color: 'gray',
    icon: 'i-mdi-cog',
    disabled: false,
  },
  {
    id: 'finances',
    name: 'Finanzas',
    description: 'Gestión de caja, presupuestos y reportes financieros.',
    color: 'green',
    icon: 'i-mdi-bank',
    disabled: false,
  },
  {
    id: 'inventory',
    name: 'Inventarios',
    description: 'Gestión de productos, almacenes y control de inventarios.',
    color: 'blue',
    icon: 'i-mdi-warehouse',
    disabled: false,
  },
  {
    id: 'management',
    name: 'Dirección',
    description: 'Gestión empresarial, indicadores de gestión y reportes.',
    color: 'amber',
    icon: 'i-mdi-finance',
    disabled: false,
  },
  {
    id: 'purchases',
    name: 'Compras',
    description: 'Gestión de proveedores, proceso de compras y órdenes de compra.',
    color: 'red',
    icon: 'i-mdi-cart',
    disabled: false,
  },
  {
    id: 'sales',
    name: 'Ventas',
    description: 'Gestión de clientes, proceso de venta y cotizaciones.',
    color: 'purple',
    icon: 'i-mdi-tag',
    disabled: false,
  },
]

// view:

export const VIEW_SETTINGS_MODULES: ViewSettings<Module> = {
  tableName: 'modules',
  views: [{ value: 'cards', label: 'Tarjetas', icon: 'i-mdi-view-module' }],
  selectedView: 'cards',
  compact: false,
  columns: [
    {
      key: 'actions',
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
    },
    {
      key: 'description',
      label: 'Descripción',
      class: 'min-w-[300px]',
    },
    {
      key: 'disabled',
      label: 'Deshabilitado',
      sortable: true,
    },
  ],
  visibleColumns: [
    'actions',
    'name',
    'description',
    'disabled',
  ],
}

export const useModules = () => {
  const route = useRoute()
  const { user } = useUserSession()

  // filter out modules without user.modules[<moduleId>].enabled === true
  const modules = computed(() => MODULES.filter(mod => user.value?.modules?.[mod.id]?.enabled))

  const moduleId = computed({
    get: () => route.path.split('/')[1] ?? 'admin',
    set: (value: string) => navigateTo(`/${value}`),
  })

  return {
    modules,
    moduleId,
  }
}
